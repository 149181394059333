import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiService } from 'src/app/core/services/api.service';
import { requests } from 'src/app/core/config/config';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})

/**
 * Topbar Component
 */
export class TopbarComponent implements OnInit {
  mode: string | undefined;
  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;
  notifications: any;
  isOpened: boolean = false;
  year: number = new Date().getFullYear();
  isRead: boolean = false;

  constructor(
    private apiService: ApiService,
    private router: Router // private authService: AuthenticationService, // private authFackservice: AuthfakeauthenticationService, // public languageService: LanguageService, // public _cookiesService: CookieService, // public translate: TranslateService, // private eventService: EventService
  ) {}

  /**
   * Language Listing
   */
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    // { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    // { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    // { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
    { text: 'Arabic', flag: 'assets/images/flags/us.jpg', lang: 'ar' },
  ];

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  layoutMode!: string;
  isNewNotification: boolean = false;

  ngOnInit(): void {
    // this.layoutMode = LAYOUT_MODE;
    this.fetchData();
    this.element = document.documentElement;
    // Cookies wise Language set
    // this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  fetchData() {
    this.apiService.sendRequest(requests.notifications, 'get', { limit: 100, pageNo: 1 }).subscribe((res: any) => {
        this.notifications = res.data?.notifications;
        this.isNewNotification = this.notifications.some( (notification) => notification.markAsRead === false );
        console.log('Notifications ==>', this.notifications);
      });
  }

  /**
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    // this.languageService.setLanguage(lang);
    // this.eventService.broadcast('changeLanguage', text);
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  changeMode(mode: string) {
    this.layoutMode = mode;
    this.mode = mode;
    // this.eventService.broadcast('changeMode', mode);
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    // if (environment.defaultauth === 'firebase') {
    // this.authService.logout();
    // } else {
    //   this.authFackservice.logout();
    // }
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  isActive() {
    return location.pathname == '/' ? 'active' : '';
  }

  toggleOpen() {
    this.isOpened = !this.isOpened;
  }

  markAsRead(notification, event) {
    // API call to set markAsRead True
    event.preventDefault();
    this.apiService.sendRequest(requests.markAsRead + notification.id, 'get').subscribe((_res: any) => {
        this.fetchData();
      });
  }

  markAllAsRead() {
    if ( this.notifications.some( (notification) => notification.markAsRead === false) ) {
      this.apiService.sendRequest(requests.markAllAsRead, 'get').subscribe((_res: any) => {
          this.fetchData();
        });
    }
  }
  openNotification(notification) {
    if (notification.markAsRead == false) {
      this.apiService.sendRequest(requests.markAsRead + notification.id, 'get').subscribe((_res: any) => {
          this.fetchData();
        });
    }
    switch (notification?.notificationTypeId) {
      case 1:
        this.router.navigate(['/disputes']);
        break;
      case 5:
        this.router.navigate(['/seekers']);
        break;
      case 9:
        this.router.navigate(['/skillers']);
        break;
    }
  }

  navigateToHomepage() {
    this.router.navigate(['']);
  }
}
