import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let admin:any = JSON.parse(localStorage.getItem('admin') || '{}');
        if (admin?.accessToken) {
                return true;
        }
        // not logged in so redirect to login page with the return url
        localStorage.clear()
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
