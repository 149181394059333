import {environment} from "../../../environments/environment";

export const requests = {

  addNewAttachment: '',
  notifications: environment.baseUrlAdmin + 'notification',

  //Admin
  getuserDetails: environment.baseUrlAdmin + 'admin/user',
  settingsApi: environment.baseUrlAdmin + 'admin/static-values',
  saveConfigApi: environment.baseUrlAdmin + 'admin/static-values/',

  //Auth
  login: environment.baseUrlAdmin + 'auth/login',
  changePassword: environment.baseUrlAdmin + 'admin/user/update-password',
  forgotPassword: environment.baseUrlAdmin + 'admin/user/forgot-password',
  updateForgotPassword: environment.baseUrlAdmin + 'admin/user/update-forgot-password',

  //Disputes
  getAllDsiputes: environment.baseUrlAdmin + 'admin/lecture/disputed-lectures',
  resolveDispute: environment.baseUrlAdmin + 'admin/lecture/disputed-lecture/',

  //Lectures
  getAllLectures: environment.baseUrlAdmin + 'admin/lecture/all-lectures',
  getLecture: environment.baseUrlAdmin + 'admin/lecture/',
  getrecordings: environment.baseUrlAdmin + 'admin/lecture/meeting/recordings',
  getLectureByUser: environment.baseUrlAdmin + 'admin/lecture/all-lectures/user/',

  //Messages
  getAllMessages: environment.baseUrlAdmin + 'admin/contact-us',
  deleteMessage: environment.baseUrlAdmin + 'admin/contact-us',

  //Reviews
  approveReview: environment.baseUrlAdmin + 'admin/review/',
  getAllreview: environment.baseUrlAdmin + 'admin/review',

  //Seeker
  getSeekers: environment.baseUrlAdmin + 'admin/user?role=1',
  getSeeker: environment.baseUrlAdmin + 'client/student/',
  deleteSeeker: environment.baseUrlAdmin + 'admin/student/',
  // getSeekers:environment.baseUrlAdmin+'admin/student',

  //Seeker Request
  getSeekersRequests: environment.baseUrlAdmin + 'admin/seeker-request/all-requests',
  updateSeekerRequest: environment.baseUrlAdmin + 'admin/seeker-request/',

  //Skiller
  getSkillers: environment.baseUrlAdmin + 'admin/teacher',
  getSkillerDetails: environment.baseUrlAdmin + 'admin/teacher/',
  deleteSkiller: environment.baseUrlAdmin + 'admin/teacher/',

  //Subjects
  getSubjects: environment.baseUrlAdmin + 'admin/subject',
  getCategories: environment.baseUrlAdmin + 'client/subject-category',

  //Transactions
  getTransactions: environment.baseUrlAdmin + 'admin/payment/transactions',

  //Notifications
  markAsRead: environment.baseUrlAdmin + 'notification/mark-as-read/',
  markAllAsRead: environment.baseUrlAdmin + 'notification/mark-all-as-read',

  //Blogs
  getAllBlogs: environment.baseUrlAdmin + 'admin/blog',
  getBlogById: environment.baseUrlAdmin + 'admin/blog/',
  addBlog: environment.baseUrlAdmin + 'admin/blog',
  updateBlog: environment.baseUrlAdmin + 'admin/blog/',
  deleteBlog: environment.baseUrlAdmin + 'admin/blog/',

  getAllAnnouncements: environment.baseUrlAdmin + 'admin/announcement',
  getAnnouncementById: environment.baseUrlAdmin + 'admin/announcement/',
  addAnnouncement: environment.baseUrlAdmin + 'admin/announcement',
  updateAnnouncement: environment.baseUrlAdmin + 'admin/announcement/',
  deleteAnnouncement: environment.baseUrlAdmin + 'admin/announcement/',

  //Attachments
  getPreSignedURL: environment.baseUrlAdmin + 'client/attachment/pre-signed-url',
  addAttachment: environment.baseUrlAdmin + 'client/attachment',
  getAttachment:environment.baseUrlAdmin+'client/attachment/',
  getGlobalAttachment:environment.baseUrlAdmin+'client/attachment/global/',
  // highlights
  getHighlight: environment.baseUrlAdmin + 'admin/highlight',
  addHighlight: environment.baseUrlAdmin + 'admin/highlight',
  getHighlightById: environment.baseUrlAdmin + 'admin/highlight/',

  //Chat
  getAllChatsByUser: environment.baseUrlAdmin + 'chat/user/',
  getChatByThread: environment.baseUrlAdmin + 'chat/user/',

  //Course
  getAllCourses:environment.baseUrlAdmin+'admin/course',
  getCourseLectures:environment.baseUrlAdmin+'course-lecture',
  updateCourseLecture:environment.baseUrlAdmin+'course-lecture/',
  approveCourse:environment.baseUrlAdmin+'admin/course/approve',
  getMyCourses:environment.baseUrlAdmin+'myCourses',

  //Course Skillers
  getCourseSkillers:environment.baseUrlAdmin+'enrolledStudents/',

}

export const patterns = {
  url: '(https?://).*?'
}
