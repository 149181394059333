<router-outlet>
  <div *ngIf="(commonStore.alertArray && commonStore.alertArray.length > 0)" class="top-alert">
    <div class="d-flex justify-content-center" *ngFor="let alert of commonStore.alertArray" (click)="closeAlert(alert)">
      <div *ngIf="alert.isOpen" [ngClass]="'toastr-'+alert.case" class="toastr p-3">

        <div class="row" *ngIf="alert.case=='success'">
          <div class="col-sm-3">
            <i class="fa fa-check icon-tick" style="font-size: 40px;"></i>
          </div>
          <div class="col-sm-9 text-left">
            <h5 class="mb-0">Operation Completed Successfully</h5>
            <p class="mb-0" *ngIf="alert && alert.message && alert.local">
              {{alert.message}}
            </p>
          </div>
        </div>

        <div class="row" *ngIf="alert.case=='error'">
          <div class="col-sm-3">
            <i class="fa fa-exclamation-triangle icon-alert" style="font-size: 40px;" aria-hidden="true"></i>
          </div>
          <div class="col-sm-9 text-left">
            <p class="mb-0" *ngIf="alert && alert.message">
              {{alert.message}}
            </p>
          </div>
        </div>

        <div class="row" *ngIf="alert.case=='info'">
          <div class="col-sm-3">
            <i class="fa-solid fa-circle-exclamation" style="font-size: 40px;"></i>
          </div>
          <div class="col-sm-9 text-left">
            <h3 class="mb-0">Info</h3>
            <p class="mb-0" *ngIf="alert && alert.message">
              {{alert.message}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-spinner></app-spinner>
</router-outlet>