import { Component, OnInit } from '@angular/core';
import { CommonStore } from '../core/services/common.store';
declare var $:any;
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(public commonStore:CommonStore) { }

  ngOnInit(): void {
    // $.getScript('/assets/js/main.js');
  }
  closeAlert(alert:any){
    this.commonStore.closeNotifier(alert);
  }
}
