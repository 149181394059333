<header class="header sticky-bar">
  <div class="container">
    <div class="main-header">
      <div class="header-left">
        <div (click)="navigateToHomepage()" class="header-logo" style="cursor: pointer"><img alt="jobBox" height="45"
                                                                                             src="assets/imgs/template/header-logo.png">
        </div>
        <span
          class="ml-10" style="background-color: #F2F4FA; cursor: default">Admin area</span>
      </div>
      <div class="header-search">
        <div class="box-search">
          <!-- <form action="">
            <input class="form-control input-search" type="text" name="keyword" placeholder="Search">
          </form> -->
        </div>
      </div>
      <div class="header-menu d-none d-md-block">
        <ul>
          <!-- <li>        <a href="http://wp.alithemes.com/html/jobbox/demos/index.html">Home </a></li>
          <li> <a href="http://wp.alithemes.com/html/jobbox/demos/page-about.html">About us </a></li>
          <li> <a href="http://wp.alithemes.com/html/jobbox/demos/page-contact.html">Contact</a></li> -->
        </ul>
      </div>
      <div class="header-right">
        <div class="block-signin">
          <!-- <a class="btn btn-default icon-edit hover-up" href="post-job.html">Post Job</a> -->
          <div class="dropdown d-inline-block">
            <a (mousedown)="fetchData()" [class.new-notification]="isNewNotification" aria-expanded="false" class="btn btn-notify"
               data-bs-display="static" data-bs-toggle="dropdown" id="dropdownNotify"
               type="button"></a>
            <ul aria-labelledby="dropdownNotify" class="dropdown-menu dropdown-menu-light dropdown-menu-end"
                style="overflow-y: scroll;height:400px; width: 450px;">
              <div class=" modalhead d-flex justify-content-between">
                <p class="peragrf">Notification</p>
                <b (click)="markAllAsRead()" style="cursor: pointer">Mark all as read</b>
              </div>

              <li *ngFor="let notification of notifications"
                  [ngClass]="notification?.markAsRead? 'bg-white' : 'bg-unread'">
                <span class="d-flex align-items-center">
                  <a (click)="openNotification(notification)"
                     [ngClass]="notification?.markAsRead? 'bg-white' : 'bg-unread'"
                     class="dropdown-item active"
                     style="white-space: normal !important; word-wrap: break-word !important; padding: 20px; cursor: pointer">{{notification?.message}}</a>
                  <!--                <i class="fa-solid fa-check"-->
                  <!--                   (click)="markAsRead(notification)"></i>-->
                  <!-- <small class="float-right">{{noti?.createdAt | date:'short'}}</small> -->
                  <span (click)="markAsRead(notification, $event)" style="cursor: pointer">
                    <img *ngIf="!notification?.markAsRead" alt="jobBox"
                         src="assets/imgs/page/notification/check-solid.svg" style="height: 24px; width: 24px; margin-right: 1rem;">
                  </span>
                </span>
                <hr class="divider">
              </li>
              <!-- <li><a class="dropdown-item" href="#">12 messages</a></li>
              <li><a class="dropdown-item" href="#">20 replies</a></li> -->
            </ul>
          </div>
          <div class="member-login"><img alt="" src="assets/imgs/icon.png">
            <div class="info-member"><strong class="color-brand-1">Admin User</strong>
              <div class="dropdown"><a aria-expanded="false" class="font-xs color-text-paragraph-2 icon-down"
                                       data-bs-display="static" data-bs-toggle="dropdown" id="dropdownProfile"
                                       type="button">Super Admin</a>
                <ul aria-labelledby="dropdownProfile" class="dropdown-menu dropdown-menu-light dropdown-menu-end">
                  <li><a [routerLink]="['/change-password']" class="dropdown-item">Change Password</a></li>
                  <!-- <li><a class="dropdown-item" href="my-resume.html">CV Manager</a></li> -->
                  <li><a (click)="logout()" [routerLink]="['/login']" class="dropdown-item">Logout</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- mobile header -->
<div (click)="toggleOpen()" [class.burger-close]="isOpened" class="burger-icon burger-icon-white"><span
  class="burger-icon-top"></span><span class="burger-icon-mid"></span><span class="burger-icon-bottom"></span></div>
<div [class.sidebar-visible]="isOpened" class="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
  <div class="mobile-header-wrapper-inner">
    <div class="mobile-header-content-area">
      <div class="perfect-scroll">
        <!--        <div class="mobile-search mobile-header-border mb-30">-->
        <!--          <form action="#">-->
        <!--            <input type="text" placeholder="Search…"><i class="fi-rr-search"></i>-->
        <!--          </form>-->
        <!--        </div>-->
        <div class="mobile-menu-wrap mobile-header-border">
          <!-- mobile menu start-->
          <nav>
            <ul class="main-menu">
              <li (click)="toggleOpen()"><a [ngClass]="isActive()" [routerLink]="['/']" class="dashboard2"><img
                alt="jobBox" src="assets/imgs/page/dashboard/dashboard.svg"><span class="name">Dashboard</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['blogs']" class="dashboard2"
                                            routerLinkActive="active"><img alt="jobBox"
                                                                          src="assets/imgs/page/dashboard/blog.svg"><span
                class="name">Blogs</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['highlights']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/highlights.svg"><span
                class="name">Highlights</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['/skillers']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/recruiters.svg"><span
                class="name">Skillers</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['seekers']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/candidates.svg"><span
                class="name">Seekers</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['subjects']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/subject.svg"><span
                class="name">Subjects</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['lectures']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/lecture.svg"><span
                class="name">Lectures</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['courses/list']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/courses.svg"><span
                class="name">Courses</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['requests']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/candidates.svg"><span
                class="name">Seeker Requests</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['transactions']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/trasfer.svg"><span
                class="name">Transactions</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['disputes']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/disputes.svg"><span
                class="name">Disputes</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['messages']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/message.svg"><span
                class="name">Customer Messages</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['reviews']" class="dashboard2"
                                            routerLinkActive="active"><img alt="jobBox"
                                                                            src="assets/imgs/page/dashboard/rating.svg"><span
                class="name">Reviews</span></a>
              </li>
              <li (click)="toggleOpen()"><a [routerLink]="['settings']" class="dashboard2"
                                            routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/settings.svg"><span
                class="name">Settings</span></a>
              </li>
              <li><a [routerLink]="['/change-password']" class="dashboard2" routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/change-password.svg"><span class="name">Change
                    Password</span></a>
              </li>
              <li (click)="logout()"><a [routerLink]="['/login']" class="dashboard2" routerLinkActive="active"><img
                alt="jobBox" src="assets/imgs/page/dashboard/logout.svg"><span class="name">Logout</span></a>
              </li>
            </ul>
          </nav>
        </div>
        <!--        <div class="site-copyright"><p class="font-sm color-text-paragraph-2">© {{year}} - <a class=""  target="_blank">Skill Yah </a></p></div>-->
      </div>
    </div>
  </div>
</div>
