import { Injectable, Type } from "@angular/core";
import { HttpHandler, HttpInterceptor, HttpEvent, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { catchError, tap } from "rxjs/operators";
import { DisableLoaderCalls, DisableNotification } from "./bypassRequests";
import { CommonStore } from "../services/common.store";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router,
    private commonStore: CommonStore,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const ifDisableLoader = DisableLoaderCalls.some(x => request.urlWithParams.match(x));
    if (!ifDisableLoader) { this.commonStore.loaderStart(); }
    console.log("interceptor");

    if (
      (request.urlWithParams.match(/login$/)) ||
      (request.urlWithParams.match(/.json$/)) ||
      (request.urlWithParams.includes('kalba-dev-bucket.s3.amazonaws.com'))
    ) {
      return next.handle(request).pipe(tap((evt: any) => {
        if (evt.body && evt.body.message) {
          this.commonStore.loaderEnd()
        }
      }),
        catchError(error => {
          this.commonStore.loaderEnd();
          if (error.statusText == 'Unknown Error') {
            this.commonStore.notifier({ action: 'error', message: 'There is something wrong with server' })
          }
          this.commonStore.notifier({ action: 'error', message: error.error?.message || error.message || 'Error Occured' });
          console.log("error in interceptor", error);
          throw (error)
        }));
    } else {
      const admin = JSON.parse(localStorage.getItem('admin') || '{}');
      let headers = request.headers;
      if (!headers.get('Authorization') && !headers.get('Upload-Image')) {
        headers = headers.set("Authorization", 'Bearer ' + admin?.accessToken);
      }
      const authReq = request.clone({ headers: headers });
      return next.handle(authReq).pipe(tap((evt: any) => {
        if (evt.body && (evt.body.message || evt.status == 200)) {

          this.commonStore.loaderEnd()
        }
      }),
        catchError(error => {
          this.commonStore.loaderEnd();
          const ifDisableLoader = DisableNotification.some(x => request.urlWithParams.match(x));

          if (!ifDisableLoader && ((error.status != 0 && error.status != 404 && error.status != 201 && error.status != 500) || (error.status == 403 && error.statusText == 'Forbidden'))) {

            if (typeof error?.error?.message === 'string') {
              console.log("error if ==> ", error);
              this.commonStore.notifier({ action: 'error', message: error.statusText == 'Forbidden' ? 'Access Denied' : error?.error?.message.match("User has been deleted. Please contact administrator.") ? 'User has been deleted. Please contact administrator.' : error.error?.message || error.message || 'Error Occured' });
            } else {
              console.log("error log else ==> ", error);

              this.commonStore.notifier({ action: 'error', message: error.error?.message[0] });
            }
            if (error.status != 400) {
              localStorage.clear();
              this.router.navigateByUrl('/login')
            }
          }
          else if (error.statusText == 'Unknown Error') {
            this.commonStore.notifier({ action: 'error', message: 'There is something wrong with server' })

            // this.router.navigateByUrl('/full/error-2');
          }
          else if ((error.status == 401 && (error.error?.message == 'Unauthorized' || error.message == 'Unauthorized')) || error.error?.message == 'Invalid Token' || error.message == 'Invalid Token') {
            window.localStorage.clear();
            // alert('el if')
            this.router.navigateByUrl('/login');
          } else {
            if(!ifDisableLoader){
              if (typeof error?.error?.message === 'string') {
                console.log("error if ==> ", error);
                this.commonStore.notifier({ action: 'error', message: error.statusText == 'Forbidden' ? 'Access Denied' : error?.error?.message.match("User has been deleted. Please contact administrator.") ? 'User has been deleted. Please contact administrator.' : error.error?.message || error.message || 'Error Occured' });
              } else {
                console.log("error log else ==> ", error);

                this.commonStore.notifier({ action: 'error', message: error.error?.message[0] });
              }
            }
          }
          console.log("error auth http call", error)
          throw (error)
        }));
    }
  }
}
