<app-topbar></app-topbar>
<main class="main">

  <app-sidebar></app-sidebar>
  <div class="box-content">
    <router-outlet>
      <!-- <div id="preloader-active">
        <div class="preloader d-flex align-items-center justify-content-center">
          <div class="preloader-inner position-relative">
            <div class="text-center"><img src="assets/imgs/template/loading.gif" alt="jobBox"></div>
          </div>
        </div>
      </div> -->
    </router-outlet>
    <app-footer></app-footer>
  </div>
</main>