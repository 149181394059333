import { AfterViewInit, Component } from '@angular/core';
import { CommonStore } from './core/services/common.store';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'Skill-Yah-Admin';
  constructor(public commonStore: CommonStore) {

    $.getScript('/assets/js/main.js');
  }
  closeAlert(alert: any) {
    this.commonStore.closeNotifier(alert);
  }
  ngAfterViewInit() {
    // $.getScript('/assets/js/plugins/armcharts5-script.js');
  }
}
