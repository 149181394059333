<footer class="footer mt-20">
    <div class="container">
      <div class="box-footer">
        <div class="row">
          <div class="col-md-6 col-sm-12 text-center text-md-start">
            <p class="font-sm color-text-paragraph-2">© {{year}} - <a class=""  target="_blank">Skill Yah </a></p>
          </div>
          <div class="col-md-6 col-sm-12 text-center text-md-end ">
            <ul class="menu-footer">
              <!-- <li><a href="#">About</a></li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Policy</a></li>
              <li><a href="#">Contact</a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>