import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'MENUITEMS.MENU.TEXT',
    //     isTitle: true
    // },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'monitor',
        link: '/',
    },
    {
        id: 3,
        label: 'MENUITEMS.APPS.TEXT',
        icon: 'user-check',
        subItems: [
            {
                id: 4,
                label: 'MENUITEMS.APPS.LIST.BUSINESS',
                link: 'business',
                parentId: 3
            },
            {
                id: 5,
                label: 'MENUITEMS.APPS.LIST.GOVERNMENT',
                link: 'gov',
                parentId: 3
            },
            {
                id: 5,
                label: 'MENUITEMS.APPS.LIST.INDIVIDUAL',
                link: 'ind',
                parentId: 3
            },
            // {
            //     id: 6,
            //     label: 'MENUITEMS.APPS.LIST.EMAIL',
            //     icon: 'bx-receipt',
            //     subItems: [
            //         {
            //             id: 7,
            //             label: 'MENUITEMS.APPS.LIST.INBOX',
            //             link: '#',
            //             parentId: 6
            //         },
            //         {
            //             id: 8,
            //             label: 'MENUITEMS.APPS.LIST.READEMAIL',
            //             link: '#',
            //             parentId: 6
            //         },
            //     ]
            // },
            // {
            //     id: 9,
            //     label: 'MENUITEMS.APPS.LIST.INVOICES',
            //     icon: 'bx-receipt',
            //     subItems: [
            //         {
            //             id: 10,
            //             label: 'MENUITEMS.APPS.LIST.INVOICELIST',
            //             link: '#',
            //             parentId: 9
            //         },
            //         {
            //             id: 11,
            //             label: 'MENUITEMS.APPS.LIST.INVOICEDETAIL',
            //             link: '#',
            //             parentId: 9
            //         },
            //     ]
            // },
            // {
            //     id: 12,
            //     label: 'MENUITEMS.APPS.LIST.CONTACTS',
            //     subItems: [
            //         {
            //             id: 13,
            //             label: 'MENUITEMS.APPS.LIST.USERGRID',
            //             link: '#',
            //             parentId: 12
            //         },
            //         {
            //             id: 14,
            //             label: 'MENUITEMS.APPS.LIST.USERLIST',
            //             link: '#',
            //             parentId: 12
            //         },
            //         {
            //             id: 15,
            //             label: 'MENUITEMS.APPS.LIST.PROFILE',
            //             link: '#',
            //             parentId: 12
            //         }
            //     ]
            // },
            // {
            //     id: 12,
            //     label: 'MENUITEMS.APPS.LIST.BLOG',
            //     badge: {
            //         variant: 'danger',
            //         text: 'MENUITEMS.APPS.BADGE',
            //     },
            //     subItems: [
            //         {
            //             id: 13,
            //             label: 'MENUITEMS.APPS.LIST.BLOGGRID',
            //             link: '#',
            //             parentId: 12
            //         },
            //         {
            //             id: 14,
            //             label: 'MENUITEMS.APPS.LIST.BLOGLIST',
            //             link: '#',
            //             parentId: 12
            //         },
            //         {
            //             id: 15,
            //             label: 'MENUITEMS.APPS.LIST.BLOGDETAILS',
            //             link: '#',
            //             parentId: 12
            //         }
            //     ]
            // }
        ]
    },
    {
        id: 3,
        label: 'MENUITEMS.APPS.LIST.COMMUNITY',
        icon: 'users',
        subItems: [
            {
                id: 4,
                label: 'MENUITEMS.APPS.LIST.MANAGECOVERS',
                link: 'cover-images',
                parentId: 3
            },
            {
                id: 4,
                label: 'MENUITEMS.APPS.LIST.NEWS',
                link: 'news',
                parentId: 3
            },
            {
                id: 5,
                label: 'MENUITEMS.APPS.LIST.HOLIDAYS',
                link: 'holidays',
                parentId: 3
            },
            {
                id: 6,
                label: 'MENUITEMS.APPS.LIST.EVENTS',
                link: 'events',
                parentId: 3
            },
            {
                id: 7,
                label: 'MENUITEMS.APPS.LIST.DESTINATIONS',
                link: 'destinations',
                parentId: 3
            }]
    },
    {
        id: 4,
        label: 'MENUITEMS.APPS.LIST.SERVICES',
        icon: 'briefcase',
        subItems: [
            {
                id: 4378783,
                label: 'MENUITEMS.APPS.LIST.ADD',
                link: '/services/add',
                parentId: 4
            },
            {
                id: 4353,
                label: 'MENUITEMS.APPS.LIST.SERVICES',
                link: '/services',
                parentId: 4
            },
            {
                id: 4354,
                label: 'MENUITEMS.APPS.LIST.SERVICETYPES',
                link: '/services/service-categories',
                parentId: 4
            },
        ]
    },
    {
        id: 4,
        label: 'MENUITEMS.APPS.LIST.INSTANTREPORTS',
        icon: 'tool',
        link: '/instant-reports'        
    },
    {
        id: 563,
        label: 'MENUITEMS.APPS.LIST.COMPLAINTS',
        icon: 'alert-triangle',
        link: '/complaints'        
    },
    {
        id: 7554,
        label: 'MENUITEMS.APPS.LIST.ADMINISTRATION',
        icon: 'user-check',
        link: '/admins',
        subItems: [
            {
                id: 342213,
                label: 'MENUITEMS.APPS.LIST.ROLES',
                link: '/admins/roles',
                parentId: 7554
            },
            {
                id: 344656,
                label: 'MENUITEMS.APPS.LIST.ADMIN',
                link: '/admins',
                parentId: 7554
            },
            {
                id: 34455,
                label: 'MENUITEMS.APPS.LIST.DEPARTMENTS',
                link: '/admins/departments',
                parentId: 7554
            },
        ]

    },
    {
        id: 4,
        label: 'MENUITEMS.APPS.LIST.SETTINGS',
        icon: 'settings',
        link: '/settings',
        subItems: [
            {
                id: 342213,
                label: 'MENUITEMS.APPS.LIST.WEBSITE',
                link: '/settings',
                parentId: 4
            },
            {
                id: 342213,
                label: 'MENUITEMS.APPS.LIST.LANDINGPAGE',
                link: '/settings/video',
                parentId: 4
            },
        ]

    },
    {
        id: 123456,
        label: 'MENUITEMS.APPS.LIST.APPLICATIONS',
        icon: 'settings',
        link: '/applications',
        subItems: [
            {
                id: 786868,
                label: 'MENUITEMS.APPS.LIST.APPLICATIONSLIST',
                link: '/applications',
                parentId: 123456
            },

        ]
    }

];

