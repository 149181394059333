export const RequestsWithoutAccesstoken=[
    'register',
    'login',
    '.js',
    'forgot-password'
];

export const DisableNotification=[
    'listusers',
    'disputed-lectures'
]
export const DisableLoaderCalls=[
    '.js',
    'settings'
]