<div [class.close-nav]="isOpened" class="nav"><a (click)="toggleOpen()" [class.btn-collapsed]="isOpened"
                                                 class="btn btn-expanded" style="margin-top: 1.2rem;"></a>
  <nav class="nav-main-menu">
    <ul class="main-menu">
      <li>
        <a [ngClass]="isActive()" [routerLink]="['/']" class="dashboard2">
          <img alt="jobBox" src="assets/imgs/page/dashboard/dashboard.svg">
          <span class="name">Dashboard</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['blogs']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/blog.svg">
          <span class="name">Blogs</span>
        </a>
        </li>
        <li>
          <a [routerLink]="['announcements']" class="dashboard2" routerLinkActive="active">
            <img alt="jobBox" src="assets/imgs/page/dashboard/blog.svg">
            <span class="name">Announcements</span>
          </a>
          </li>

      <li>
        <a [routerLink]="['highlights']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/highlights.svg">
          <span class="name">Highlights</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/skillers']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/recruiters.svg">
          <span class="name">Skillers</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['seekers']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/candidates.svg">
          <span class="name">Seekers</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['subjects']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/subject.svg">
          <span class="name">Subjects</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['lectures']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/lecture.svg">
          <span class="name">Lectures</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['courses']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/courses.svg">
          <span class="name">Courses</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['requests']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/candidates.svg">
          <span class="name">Seeker Requests</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['transactions']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/trasfer.svg">
          <span class="name">Transactions</span>
        </a>
      </li>
      <!-- <li>
      <a class="dashboard2" routerLinkActive="active" [routerLink]="['users']">
      <img src="assets/imgs/page/dashboard/profiles.svg" alt="jobBox">
      <span class="name">Users</span>
      </a>
      </li> -->
      <li>
        <a [routerLink]="['disputes']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/disputes.svg">
          <span class="name">Disputes</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['messages']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/message.svg">
          <span class="name">Customer Messages</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['reviews']" class="dashboard2" routerLinkActive="active">
          <img alt="jobBox" src="assets/imgs/page/dashboard/rating.svg">
          <span class="name">Reviews</span>
        </a>
      </li>
      <!-- <li>
      <a class="dashboard2" href="recruiters.html">
      <img src="assets/imgs/page/dashboard/recruiters.svg" alt="jobBox">
      <span class="name">Recruiters</span>
      </a>
      </li>
      <li>
      <a class="dashboard2" href="my-job-grid.html">
      <img src="assets/imgs/page/dashboard/jobs.svg" alt="jobBox">
      <span class="name">My Jobs</span>
      </a>
      </li>
      <li>
      <a class="dashboard2" href="my-tasks-list.html">
      <img src="assets/imgs/page/dashboard/tasks.svg" alt="jobBox">
      <span class="name">Tasks List</span>
      </a>
      </li>
      <li>
      <a class="dashboard2" href="profile.html">
      <img src="assets/imgs/page/dashboard/profiles.svg" alt="jobBox">
      <span class="name">My Profiles</span>
      </a>
      </li>
      <li>
      <a class="dashboard2" href="my-resume.html">
      <img src="assets/imgs/page/dashboard/cv-manage.svg" alt="jobBox">
      <span class="name">CV Manage</span>
      </a>
      </li>
      <li>
      <a class="dashboard2" href="settings.html">
      <img src="assets/imgs/page/dashboard/settings.svg" alt="jobBox">
      <span class="name">Setting</span>
      </a>
      </li>
      <li>
      <a class="dashboard2" href="authentication.html">
      <img src="assets/imgs/page/dashboard/authentication.svg" alt="jobBox">
      <span class="name">Authentication</span>
      </a>
      </li>
      <li>
      <a class="dashboard2 active" href="login.html">
      <img src="assets/imgs/page/dashboard/logout.svg" alt="jobBox">
      <span class="name">Logout</span>
      </a>
      </li> -->
      <li><a [routerLink]="['settings']" class="dashboard2" routerLinkActive="active">
        <img alt="jobBox" src="assets/imgs/page/dashboard/settings.svg">
        <span class="name">Settings</span>
      </a>
      </li>
    </ul>
  </nav>
  <!-- <div class="box-profile-completed text-center mb-30">
    <div id="circle-staticstic-demo"></div>
    <h6 class="mb-10">Profile Completed</h6>
    <p class="font-xs color-text-mutted">Please add detailed information to your profile. This will help you develop your career more quickly.</p>
  </div> -->
  <!-- <div class="border-bottom mb-20 mt-20"></div> -->
  <!-- <div class="box-profile-completed text-center mb-30">
    <div id="circle-staticstic-demo"></div>
    <h6 class="mb-10">Profile Completed</h6>
    <p class="font-xs color-text-mutted">Please add detailed information to your profile. This will help you develop your career more quickly.</p>
  </div>
  <div class="sidebar-border-bg mt-50"><span class="text-grey">WE ARE</span><span class="text-hiring">HIRING</span>
    <p class="font-xxs color-text-paragraph mt-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae architecto</p>
    <div class="mt-15"><a class="btn btn-paragraph-2" href="#">Know More</a></div>
  </div> -->
</div>
